/* src/styles/FixturesList.css */

.fixtures-list {
  margin-bottom: 20px;
}

.fixtures-list table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.fixtures-list th,
.fixtures-list td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.fixtures-list th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.fixtures-list .team-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.fixtures-list input[type='number'] {
  width: 40px;
  /* padding: 4px; */
  margin: 0 4px;
  text-align: center;
}

/* src/styles/FixturesList.css */
.fixtures-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.auto-populate-buttons {
  display: flex;
  gap: 10px;
}

.auto-populate-button,
.favourites-button,
.reset-button {
  position: relative;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.auto-populate-button:hover,
.favourites-button:hover,
.reset-button:hover {
  background-color: #e0e0e0;
}

.auto-populate-button[title]:hover::after,
.favourites-button[title]:hover::after,
.reset-button[title]:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 4px;
  z-index: 1;
}

.favourites-button {
  font-size: 18px;
  line-height: 1;
}

.reset-button {
  font-size: 18px;
  line-height: 1;
}

@media screen and (max-width: 768px) {

  .score-input {
    width: 130px;
    text-align: center;
  }

  .fixtures-list td {
    padding: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    font-size: 11px;
  }

  .fixtures-list th {
    padding: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    font-size: 13px;
  }
}


/* 

@media screen and (max-width: 768px) {
  .fixtures-list table {
    font-size: 14px;
  }

  .fixtures-list th,
  .fixtures-list td {
    padding: 8px;
  }

  .fixtures-list th:first-child,
  .fixtures-list td:first-child {
    width: 25%;
  }

  .fixtures-list th:nth-child(2),
  .fixtures-list th:nth-child(4),
  .fixtures-list td:nth-child(2),
  .fixtures-list td:nth-child(4) {
    width: 30%;
  }

  .fixtures-list th:nth-child(3),
  .fixtures-list td:nth-child(3) {
    width: 15%;
  }
}  */