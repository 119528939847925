/* src/styles/StandingsTable.css */

.standings-table {
    margin-bottom: 20px;
  }
  
  .standings-table table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  
  .standings-table th,
  .standings-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }


.standings-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

  
  .standings-table .team-logo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    vertical-align: middle;
  }

  .standings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

.share-button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.share-button:hover {
  background-color: #e0e0e0;
}