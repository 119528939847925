/* src/styles/App.css */
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 20px; */
}

.app-logo {
  width: 150px;
  /* Adjust the size as needed */
  height: 150px;
  margin-right: 10px;
}

.app-title {
  text-align: center;
}

.app-name {
  font-size: 54px;
  font-weight: bold;
  margin: 0;
}

.app-tagline {
  font-size: 16px;
  margin: 0;
}

.app-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.gap-div {
  width: 20px;
  /* Adjust the width as needed */
}

.app-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  /* 
  max-width: 1200px; 
  margin: 0 auto; */
  margin: 10px;
}

.fixtures-container {
  flex: 1;
  margin-right: 20px;
}

.standings-container {
  flex: 1;
}

.mobile-only {
  display: none;
}

/* src/styles/App.css */
/* ... (existing styles) */

.learn-more-button {
  background-color: #cc0000;
  border: none;
  color: #ffffff;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  margin-left: auto;
}

.learn-more-button:hover {
  background-color: #aa0000;
}

@media screen and (max-width: 768px) {

.mobile-only {
    display: block;
  }

  .app-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
  }
  

  .app-content-wrapper {
    flex-direction: column;
    padding: 10px;
  }

  .fixtures-container {
    margin-right: 0;
    margin-bottom: 20px;
  }


  .app-name {
    font-size: 42px;
    font-weight: bold;
    margin: 0;
  }
  
  .app-tagline {
    font-size: 12px;
    margin: 0;
  }

  .app-logo {
    width: 100px;
    /* Adjust the size as needed */
    height: 100px;
    margin-right: 10px;
  }
  
  .team-name {
    display: none;
  }

}

